body {
  padding-top: 16px;
}

main {
  margin-bottom: 80px !important;
}

aside ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 10px 0;
}

aside li {
  padding: 6px 0;
}

aside li a,
aside button {
  text-decoration: none !important;
  color: #2fa4e7 !important;
  padding: 0 !important;

}

h4 {
  margin: 4px 0 12px;
  padding: 16px 0;
  text-align: center
}

.table tr td:first-child {
  font-weight: 700;
  width: 35%
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none
}

@media (max-width: 992px) {
  main {
    margin-bottom: 54px;
  }
}
