.site_footer {
  bottom: 0;
  height: 60px;
  padding-top: 14px;
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);

  &__copyright {
    padding-top: 6px;
  }
}

@media (max-width: 992px) {
  footer {
    height: 46px;
    padding-top: 6px
  }
}